import './App.css';
import { Routes, Route } from "react-router-dom";
import Index from './components';
import Calendar from './components/calendar';
import Rasiphalalu from './components/rasiphalalu';
import Panchangam from './components/panchagam';
import Bhavishyavani from './components/astrology/bhavishyavani';
import Grahalu from './components/astrology/grahalu';
import Sankyashastram from './components/astrology/sankyashastram';
import Vastuchitkalu from './components/astrology/vastuchitkalu';
import Pillishastram from './components/shakunashastram/pillishastram';
import Ballishastram from './components/shakunashastram/ballishastram';
import Villuvinasamacharam from './components/villuvinasamacharam/villuvinasamacharam';
import Samskrutham from './components/villuvinasamacharam/samskrutham';
import Samethalu from './components/villuvinasamacharam/samethalu';
import Nithipadhyalu from './components/villuvinasamacharam/nithipadhyalu';
import Nithyasathyalu from './components/villuvinasamacharam/nithyasathyalu';
import Rastralabashalu from './components/villuvinasamacharam/rastralabashalu';
import Grahanam from './components/villuvinasamacharam/grahanam';
import Sankyapradanyata from './components/etharamulu/sankyapradanyata';
import Telugusamskrutham from './components/etharamulu/telugusamskrutham';
import Namakaranam from './components/etharamulu/namakaranam';
import Upavasam from './components/bhakthiranjini/upavasam';
import Thirdayathra from './components/bhakthiranjini/thirdayathra';
import Slokalu from './components/bhakthiranjini/slokalu';
import Shotramlu from './components/bhakthiranjini/shotramlu';
import Ashtotharalu from './components/bhakthiranjini/ashtotharalu';
import Athyadhimikashakthi from './components/bhakthiranjini/athyadhimikashakthi';
import Muhurthalu from './components/nakshatrapontana/muhurthalu';
import Vivahanakshatralu from './components/nakshatrapontana/vivahanakshatralu';
import Dharmasandehalu from './components/hindudarmavaibavam/dharmasandehalu';
import Jeevanashaili from './components/hindudarmavaibavam/jeevanashaili';
import Masavishistatha from './components/hindudarmavaibavam/masavishistatha';
import Srustirahasyalu from './components/hindudarmavaibavam/srustirahasyalu';
import Hindudarmashastram from './components/hindudarmavaibavam/hindudarmashastram';
import Pandugapramukyata from './components/hindudarmavaibavam/pandugapramukyata';
import Mukhyamainasamacharam from './components/mukhyamainasamacharam';
import Astakalu from './components/astakalu';
import Karthikapuranam from './components/karthikapuranam';
import Pandugalu from './components/pandugalu';
import Panchangam1 from './components/etharamulu/panchangam1';
import Ramayanams from './components/ramayanam';
import Mahabharatam from './components/mahabharatham/mahabharatam';
import Adhiparvam from './components/mahabharatham/adhiparvam';
import Sabhaparvam from './components/mahabharatham/sabhaparvam';
import Vanaparvam from './components/mahabharatham/vanaparvam';
import Virataparvam from './components/mahabharatham/virataparvam';
import Vudhyogaparvam from './components/mahabharatham/vudhyogaparvam';
import Bhishmaparvam from './components/mahabharatham/bhishmaparvam';
import Dhronaparvam from './components/mahabharatham/dhronaparvam';
import Karnaparvam from './components/mahabharatham/karnaparvam';
import Salyaparvam from './components/mahabharatham/salyaparvam';
import Swothikaparvam from './components/mahabharatham/swothikaparvam';
import Sriparvam from './components/mahabharatham/sriparvam';
import Santhiparvam from './components/mahabharatham/santhiparvam';
import Anusasanikaparvam from './components/mahabharatham/anusasanikaparvam';
import Aswamedhaparvam from './components/mahabharatham/aswamedhaparvam';
import Asramavasaparvam from './components/mahabharatham/asramavasaparvam';
import Mousalaparvam from './components/mahabharatham/mousalaparvam';
import Mahaprasthanikaparvam from './components/mahabharatham/mahaprasthanikaparvam';
import Swargarohanaparvam from './components/mahabharatham/swargarohanaparvam';
import Krishnajananam from './components/bhagavathgita/srikrishunijivitham';
import Pandugavisistatha from './components/bhagavathgita/pandugavisistatha';
import Bhagavathgita from './components/bhagavathgita/bhagavathgita';
import Pandugaluu from './components/bhagavathgita/pandugalu';
import Srikrishnaastrotharalu from './components/bhagavathgita/srikrishnaastrotharalu';
import Srikrishnastakam from './components/bhagavathgita/srikrishnastakam';
import Sukthulu from './components/bhagavathgita/sukthulu';
import Vasthavalu from './components/bhagavathgita/vasthavalu';
import Geethasaramsam from './components/bhagavathgita/geethasaramsam'
import Panchagammain from './components/panchagammain';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();
  console.log(location,'location')
  const getPageTitle = () => {
      switch (location.pathname) {
          case '/Index':
              return 'Telugu Calendar Panchangam App | 2024 Holidays | Raasi Phalalu | Horoscope | iPhone & Android Apps';
          case '/Panchangam':
              return 'Telugu Calendar Panchangam App | Panchangam | తెలుగు | పంచాంగం';
          case '/calendar':
             return 'Telugu Calendar Panchangam App | Calendar  | తెలుగు | క్యాలెండర్';
          case '/Rasi-Phalalu':
              return 'Telugu Calendar Panchangam App | RasiPhalalu  | తెలుగు | రాశి ఫలాలు';
          case '/Upavasam':
              return 'Telugu Calendar Panchangam App | Upavasam  | తెలుగు | ఉపవాసం';
          case '/Athyadhimi-Kashakthi':
              return 'Telugu Calendar Panchangam App | Athyadhimika shakthi  | తెలుగు | ఆథ్యాధిమిక శక్తి';
          case '/Thidra-Yatra':
              return 'Telugu Calendar Panchangam App | Thidra Yatra  | తెలుగు | తీర్థయాత్ర';
          case '/Shotramlu':
              return 'Telugu Calendar Panchangam App | Shotramlu | తెలుగు | శోత్రములు';
          case '/Muhurthalu':
              return 'Telugu Calendar Panchangam App | Muhurthalu | తెలుగు | ముహూర్తాలు';
          case '/Vivaha-Nakshatralu':
              return 'Telugu Calendar Panchangam App | Vivaha Nakshatralu | తెలుగు | వివాహ నక్షత్రాలు';
          case '/Dharmasandehalu':
              return 'Telugu Calendar Panchangam App | Dharmasandehalu | తెలుగు | ధర్మసందేహాలు';
          case '/Hindu-Darmashastram':
              return 'Telugu Calendar Panchangam App | Hindu Darmashastram | తెలుగు | హిందూ ధర్మశాస్త్రం';
          case '/Pandugapramukyata':
              return 'Telugu Calendar Panchangam App | Pandugapramukyata | తెలుగు | పండుగ ప్రాముఖ్యత';
          case '/Masa-Vishistatha':
               return 'Telugu Calendar Panchangam App | Masa Vishistatha | తెలుగు | మాస విశిష్టత';
          case '/Srusti-Rahasyalu':
                return 'Telugu Calendar Panchangam App | Srusti Rahasyalu | తెలుగు | సృష్టి రహస్యాలు';
          case '/Jeevanashaili':
              return 'Telugu Calendar Panchangam App | Jeevanashaili | తెలుగు | జీవనశైలి';
          case '/Sankya-Shastram':
              return 'Telugu Calendar Panchangam App | Sankya Shastram | తెలుగు | సంఖ్యా శాస్త్రం';
          case '/Grahalu':
              return 'Telugu Calendar Panchangam App | Grahalu | తెలుగు | గ్రహాలు';
          case '/Bhavishyavani':
              return 'Telugu Calendar Panchangam App | Bhavishyavani | తెలుగు | భవిష్యవాణి';
          case '/Vasthu-Chitkalu':
              return 'Telugu Calendar Panchangam App | Vasthu Chitkalu | తెలుగు | వాస్తు చిట్కాలు';
          case '/Balli-Shastram':
              return 'Telugu Calendar Panchangam App | Balli Shastram | తెలుగు | బల్లి శాస్త్రం';
          case '/Pillishastram':
              return 'Telugu Calendar Panchangam App | Pillishastram | తెలుగు | పిల్లిశాస్త్రం';
          case '/Villuvina-Samacharam':
              return 'Telugu Calendar Panchangam App | Villuvina Samacharam | తెలుగు | విలువైన సమాచారం';
          case '/villuvina-Samacharam':
                return 'Telugu Calendar Panchangam App | Villuvina Samacharam | తెలుగు | విలువైన సమాచారం';
  
          case '/Samskrutham':
                return 'Telugu Calendar Panchangam App | Samskrutham | తెలుగు | సంస్కృతం';
          case '/Samethalu':
                return 'Telugu Calendar Panchangam App | Samethalu | తెలుగు | సామెతలు';
          case '/Rastrala-Bashalu':
                return 'Telugu Calendar Panchangam App | Rastra Bashalu | తెలుగు | రాష్ట్ర భాషలు';
          case '/Nithya-Sathyalu':
                return 'Telugu Calendar Panchangam App | Nithya Sathyalu | తెలుగు | నిత్య సత్యలు';
          case '/Nithi-Padhyalu':
                  return 'Telugu Calendar Panchangam App | Nithi Padhyalu | తెలుగు | నీతి పద్యాలు';
          case '/Grahanam':
                return 'Telugu Calendar Panchangam App | Grahanam | తెలుగు | గ్రహణం';
          case '/Telugu-Samskrutham':
                return 'Telugu Calendar Panchangam App | Telugu Samskrutham | తెలుగు | తెలుగు సంస్కృతం';
          case '/Sankya-Pradanyata':
                return 'Telugu Calendar Panchangam App | Sankya Pradanyata | తెలుగు | సంఖ్య ప్రాధాన్యత';
          case '/Mukhyamainasamacharam':
                return 'Telugu Calendar Panchangam App | Mukhyamainasamacharam | తెలుగు | ముఖ్యమైనసమాచారం';
          case '/Astakalu':
                return 'Telugu Calendar Panchangam App | Astakalu | తెలుగు | అష్టకాలు';
          case '/Karthikapuranam':
               return 'Telugu Calendar Panchangam App | Karthikapuranam | తెలుగు | కార్తీకపురాణం';
          case '/Namakaranam':
               return 'Telugu Calendar Panchangam App | Namakaranam | తెలుగు | నామకరణం';
          case '/Pandugalu':
                return 'Telugu Calendar Panchangam App | Pandugalu | తెలుగు | పండుగలు';
          case '/Telugu-Panchangam':
                return 'Telugu Calendar Panchangam App | Telugu Panchangam | తెలుగు | తెలుగు పంచాంగం';
          case '/Ramayanam':
                return 'Telugu Calendar Panchangam App | Ramayanam | తెలుగు | రామాయణం';
          case '/Mahabharatam':
              return 'Telugu Calendar Panchangam App | Mahabharatam | తెలుగు | మహాభారతం';
          case '/Adhi-Parvam':
                return 'Telugu Calendar Panchangam App | Adi Parvam | తెలుగు | ఆది పర్వం';
          case '/Sabha-Parvam':
                return 'Telugu Calendar Panchangam App | Sabha Parvam | తెలుగు | సభ పర్వం';
          case '/Vana-Parvam':
                return 'Telugu Calendar Panchangam App | Vana Parvam | తెలుగు | వన పర్వం';
          case '/Virata-Parvam':
                return 'Telugu Calendar Panchangam App | Virata Parvam | తెలుగు | విరాట పర్వం';
          case '/Vudhyoga-Parvam':
                return 'Telugu Calendar Panchangam App | Vudhyoga Parvam | తెలుగు | ఉద్యోగ పర్వం';
          case '/Bhishma-Parvam':
                return 'Telugu Calendar Panchangam App | Bhishma Parvam | తెలుగు | భీష్మ పర్వం';
          case '/Dhrona-Parvam':
              return 'Telugu Calendar Panchangam App | Dhrona Parvam | తెలుగు | ద్రోణ పర్వం';
          case '/Karna-Parvam':
                return 'Telugu Calendar Panchangam App | Karna Parvam | తెలుగు | కర్ణ పర్వం';
          case '/Salya-Parvam':
              return 'Telugu Calendar Panchangam App | Salya Parvam | తెలుగు | శల్య పర్వం';
          case '/Swothika-Parvam':
                return 'Telugu Calendar Panchangam App | Swothika Parvam | తెలుగు | స్వోతిక పర్వం';
          case '/Sri-Parvam':
              return 'Telugu Calendar Panchangam App | Sri Parvam | తెలుగు | శ్రీ పర్వం';
          case '/Santhi-Parvam':
              return 'Telugu Calendar Panchangam App | Santhi Parvam | తెలుగు | శాంతి పర్వం';
          case '/Anusasanika-Parvam':
              return 'Telugu Calendar Panchangam App | Anusasanika Parvam | తెలుగు | అనుశాసనిక పర్వం';
          case '/Aswamedha-Parvam':
              return 'Telugu Calendar Panchangam App | Aswamedha Parvam | తెలుగు | అశ్వమేధ పర్వం';
          case '/Asramavasa-Parvam':
                return 'Telugu Calendar Panchangam App | Asramavasa Parvam | తెలుగు | ఆశ్రమవాస పర్వం';
          case '/Mousala-Parvam':
                return 'Telugu Calendar Panchangam App | Mousala Parvam | తెలుగు | మౌసల పర్వం';
          case '/Mahaprasthanika-Parvam':
                return 'Telugu Calendar Panchangam App | Mahaprasthanika Parvam | తెలుగు | మహాప్రస్థానిక పర్వం';
          case '/Swargarohana-Parvam':
                return 'Telugu Calendar Panchangam App | Swargarohana Parvam | తెలుగు | స్వర్గారోహణ పర్వం';
          case '/Krishnani-Jananam':
                  return 'Telugu Calendar Panchangam App | Krishna Jananam | తెలుగు | కృష్ణుడు జననం';
          case '/Pandugala-Visistatha':
              return 'Telugu Calendar Panchangam App | Pandugala Visistatha | తెలుగు | పండుగల విశిష్టత';
          case '/Geetha-Saramsam':
                return 'Telugu Calendar Panchangam App | Geetha Saramsam | తెలుగు | గీతా సారంసం';
          case '/Bhagavathgita':
              return 'Telugu Calendar Panchangam App | Bhagavathgita | తెలుగు | భగవద్గీత';
          case '/Pandugaluu':
                return 'Telugu Calendar Panchangam App | Pandugaluu | తెలుగు | పండుగలు';
          case '/Sri-Krishna-Astrotharalu':
                return 'Telugu Calendar Panchangam App | Sri Krishna Astrotharalu | తెలుగు | శ్రీకృష్ణ అస్త్రోత్తరాలు';
          case '/Sri-Krishnastakam':
                return 'Telugu Calendar Panchangam App | Sri Krishnastakam | తెలుగు | శ్రీ కృష్ణాష్టకం';
          case '/Bhakthi-Sukthulu':
              return 'Telugu Calendar Panchangam App | Bhakthi Sukthulu | తెలుగు | భక్తి సూక్తులు';
          case '/Vasthavalu':
                return 'Telugu Calendar Panchangam App | Vasthavalu | తెలుగు | వాస్తవాలు';
          default:
              return 'Telugu Calendar Panchangam App | 2024 Holidays | Raasi Phalalu | Horoscope | iPhone & Android Apps';
      }
  };

//   const handleContextMenu = (event) => {
//     event.preventDefault();
//   };onContextMenu={handleContextMenu}
  
  return (
    <div className="App" >
        <Helmet>
                <title>{getPageTitle()}</title>
        </Helmet>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/Index" element={<Index />} />
        <Route path="/panchagam" element={<Panchangam />} />
        <Route path="/Panchangam" element={<Panchagammain />} />
        <Route path="/Calendar" element={<Calendar />} />
        <Route path="/Rasi-Phalalu" element={<Rasiphalalu />} />
        <Route path="/Upavasam" element={<Upavasam />} />
        <Route path="/Athyadhimika-shakthi" element={<Athyadhimikashakthi />} />
        <Route path="/Thidra-Yatra" element={<Thirdayathra />} />
        <Route path="/Shotramlu" element={<Shotramlu />} />
        <Route path="/Ashtotharalu" element={<Ashtotharalu />} />
        <Route path="/Slokalu" element={<Slokalu />} />
        <Route path="/Muhurthalu" element={<Muhurthalu />} />
        <Route path="/Vivaha-Nakshatralu" element={<Vivahanakshatralu />} />
        <Route path="/Dharmasandehalu" element={<Dharmasandehalu />} />
        <Route path="/Hindu-Darmashastram" element={<Hindudarmashastram />} />
        <Route path="/Pandugapramukyata" element={<Pandugapramukyata />} />
        <Route path="/Masa-Vishistatha" element={<Masavishistatha />} />
        <Route path="/Srusti-Rahasyalu" element={<Srustirahasyalu />} />
        <Route path="/Jeevanashaili" element={<Jeevanashaili />} />
        <Route path="/Sankya-Shastram" element={<Sankyashastram />} />
        <Route path="/Grahalu" element={<Grahalu />} />
        <Route path="/Bhavishyavani" element={<Bhavishyavani />} />
        <Route path="/Vasthu-Chitkalu" element={<Vastuchitkalu />} />
        <Route path="/Balli-Shastram" element={<Ballishastram />} />
        <Route path="/Pillishastram" element={<Pillishastram />} />
        <Route path="/Villuvina-Samacharam" element={<Villuvinasamacharam />} />
        <Route path="/Samskrutham" element={<Samskrutham />} />
        <Route path="/Samethalu" element={<Samethalu />} />
        <Route path="/Rastrala-Bashalu" element={<Rastralabashalu />} />
        <Route path="/Nithya-Sathyalu" element={<Nithyasathyalu />} />
        <Route path="/Nithi-Padhyalu" element={<Nithipadhyalu />} />
        <Route path="/Grahanam" element={<Grahanam />} />
        <Route path="/Telugu-Samskrutham" element={<Telugusamskrutham />} />
        <Route path="/Panchangam" element={<Panchangam />} />
        <Route path="/Sankya-Pradanyata" element={<Sankyapradanyata />} />
        <Route path="/Mukhyamainasamacharam" element={<Mukhyamainasamacharam />} />
        <Route path="/Astakalu" element={<Astakalu />} />
        <Route path="/Karthikapuranam" element={<Karthikapuranam />} />
        <Route path="/Namakaranam" element={<Namakaranam />} />
        <Route path="/Pandugalu" element={<Pandugalu />} />
        <Route path="/Telugu-Panchangam" element={<Panchangam1 />} />
        <Route path="/Ramayanam" element={<Ramayanams />} />
        <Route path="/Mahabharatam" element={<Mahabharatam />} />
        <Route path="/Adhi-Parvam" element={<Adhiparvam />} />
        <Route path="/Sabha-Parvam" element={<Sabhaparvam />} />
        <Route path="/Vana-Parvam" element={<Vanaparvam />} />
        <Route path="/Virata-Parvam" element={<Virataparvam />} />
        <Route path="/Vudhyoga-Parvam" element={<Vudhyogaparvam />} />
        <Route path="/Bhishma-Parvam" element={<Bhishmaparvam />} />
        <Route path="/Dhrona-Parvam" element={<Dhronaparvam />} />
        <Route path="/Karna-Parvam" element={<Karnaparvam />} />
        <Route path="/Salya-Parvam" element={<Salyaparvam />} />
        <Route path="/Swothika-Parvam" element={<Swothikaparvam />} />
        <Route path="/Sri-Parvam" element={<Sriparvam />} />
        <Route path="/Santhi-Parvam" element={<Santhiparvam />} />
        <Route path="/Anusasanika-Parvam" element={<Anusasanikaparvam />} />
        <Route path="/Aswamedha-Parvam" element={<Aswamedhaparvam />} />
        <Route path="/Asramavasa-Parvam" element={<Asramavasaparvam />} />
        <Route path="/Mousala-Parvam" element={<Mousalaparvam />} />
        <Route path="/Mahaprasthanika-Parvam" element={<Mahaprasthanikaparvam />} />
        <Route path="/Swargarohana-Parvam" element={<Swargarohanaparvam />} />
        <Route path="/Krishnani-Jananam" element={<Krishnajananam />} />
        <Route path="/Pandugala-Visistatha" element={<Pandugavisistatha />} />
        <Route path="/Geetha-Saramsam" element={<Geethasaramsam />} />
        <Route path="/Bhagavathgita" element={<Bhagavathgita />} />
        <Route path="/Pandugaluu" element={<Pandugaluu />} />
        <Route path="/Sri-Krishna-Astrotharalu" element={<Srikrishnaastrotharalu />} />
        <Route path="/Sri-Krishnastakam" element={<Srikrishnastakam />} />
        <Route path="/Bhakthi-Sukthulu" element={<Sukthulu />} />
        <Route path="/Vasthavalu" element={<Vasthavalu />} />

      </Routes>
    </div>
  );
}

export default App;
